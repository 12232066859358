import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import veteranImage from "../images/veterans-1.png"
import stoneTexture from "../images/stone-texture (1).jpg"
import flag from "../images/flag.jpg"

import {Helmet} from "react-helmet";
import OpenGraphLogo from "../images/open-graph-logo.png"

function Arrow(){
    return(
        <svg className={"h-7 w-7 fill-red-500 inline-block mr-2 pb-1"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"/></svg>
    )
}

function Veterans() {
    return (
        <>
            <Helmet>
                <title>Kinetic Impact | Veterans</title>
                <meta property="og:image" content={OpenGraphLogo}></meta>
            </Helmet>
            <Nav/>
            <img className={"absolute -z-10 h-152 min-w-full object-cover object-top"} src={veteranImage} alt={"veteran image"}/>
            <div className={"bg-black/50"}>
            <div className={"h-152 p-10 flex flex-col justify-center items-center text-white max-w-4xl mx-auto "}>
                <h1 className={"lg:text-8xl text-6xl text-center pb-14"}>Proudly Helping Veterans</h1>
                <p className={"text-2xl text-center"}>We are proud members of the VA San Diego Community Care Network.</p>
            </div>
            </div>
            <div className={"bg-[#0b2964] text-white p-10 lg:py-24 text-center "}>
                <p className={"max-w-4xl mx-auto lg:text-4xl text-3xl leading-normal"}>If you are a Veteran with <span className={"font-bold"}>lower back pain</span> enrolled in the VA San Diego Healthcare System you can now get the high quality care you deserve right here at Kinetic Impact <span className={"border-b-2 font-bold border-b-red-500"}>at no cost to you!</span></p>
            </div>
            <div className={"lg:p-20 p-10 max-w-6xl mx-auto"}>
                <h1 className={"text-red-500 text-5xl  text-center lg:pb-20 pb-10"}>Step By Step Instructions</h1>
                <h2 className={"text-3xl font-bold"}>Contact your VA Primary Care Provider 1 of 3 ways</h2>
                <p className={"p-6 pb-0 text-xl"}><Arrow/>Online using <a className={"underline text-blue-500"} href={"https://www.myhealth.va.gov/"}>My HealtheVet</a> secure messaging (if you do not have secure messaging access use one of the next 2 options)</p>
                <p className={"p-6 pb-0 text-xl"}><Arrow/>Use the VA San Diego Primary Care Line and leave a message for your primary care team using this direct line - (858) 552-8585 ext. 7475</p>
                <p className={"p-6 text-xl"}><Arrow/>Make an in-person appointment with your primary care provider (if you haven’t been in for a while they may require you to come in for your yearly check in)</p>
                <h2 className={"text-3xl font-bold pt-10"}>Request an authorization to receive Chiropractic care from a community provider</h2>
                <p className={"p-6 pb-0 text-xl"}><Arrow/>Tell your VA Primary Care Provider: “I have back/neck/sciatica pain and would like an authorization to receive treatment by a chiropractor that is a part of the VA Community Care Network.”</p>
                <h2 className={"text-3xl font-bold pt-10"}>Once they confirm they are putting in the authorization for you</h2>
                <p className={"p-6 pb-0 text-xl"}><Arrow/>Just call or text us directly at (619) 695-0688 to let us know and set up your appointment.</p>
                <p className={"p-6 pb-0 text-xl"}><Arrow/>Our answering service is ready to take your call and set up an initial appointment 24 hours a day!</p>
                <p className={"p-6 pb-0 text-xl"}><Arrow/>We will contact the VA and TriWest for you to handle everything else from here!</p>

            </div>
            <img className={"absolute -z-10 h-152 min-w-full object-cover object-bottom"} src={stoneTexture} alt={"veteran image"}/>
            <div className={" text-white p-10 py-20 text-center bg-black/50"}>
                <p className={"max-w-6xl mx-auto lg:text-4xl text-3xl leading-normal"}>If you have any questions or need any help with this process, please do not hesitate to call or text us directly at <a className={"underline"} href={"tel:6196950688"}>(619) 695-0688</a>, or email <a className={"underline"} href={"mailto:info@kirehab.com"}>info@kirehab.com!</a></p>
            </div>

            <img className={"absolute -z-10 h-96 min-w-full object-cover object-top"} src={flag} alt={"veteran image"}/>
            <div className={"bg-black/50 h-96"}>
            <div className={"max-w-6xl h-96 mx-auto my-auto p-10 lg:py-36  flex flex-col items-center justify-center align-middle"}>
                <p className={"text-5xl leading-normal text-white text-center"}>Thank You for Your Service!</p>
            </div>
            </div>





            <Footer/>
        </>
    )
}

export default Veterans